export function scrollLink(wrapper = "scroll-link") {
    let smoothScrollLinks = document.querySelectorAll("." + wrapper);

    smoothScrollLinks.forEach(link => {
        let href = link.getAttribute('href');
        let targetId = href.substring(1);
        console.log(targetId);
        let targetSection = document.getElementById(targetId);
        let headerOffset = parseInt(link.getAttribute('data-header-offset')) || 125;

        if (href && href.startsWith('#')) {  // Check if the link starts with "#"
            window.addEventListener('scroll', function (e) {
                let scrollPosition = window.scrollY;
                if (targetSection) {
                    if (
                        scrollPosition >= targetSection.offsetTop - headerOffset &&
                        scrollPosition < targetSection.offsetTop + targetSection.offsetHeight - headerOffset
                    ) {
                        link.classList.add('!text-clementine-500');
                    } else {
                        link.classList.remove('!text-clementine-500');
                    }
                }
            });

            if (targetSection) {
                link.addEventListener('click', function(e) {
                    e.preventDefault();
                    window.scroll({
                        behavior: 'smooth',
                        top: targetSection.offsetTop - headerOffset,
                    });
                    // console.log(targetId + '|' + targetSection.offsetTop + '|' + headerOffset);
                });
            }
        }
    });
}
